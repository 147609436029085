import React from "react"
import Layout from "../components/new-design/Layout"
// import Title from "../components/sections/title"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import HeadSection from "../components/new-design/HeadSection"
import Container from "../components/new-design/Container"
export default function Blogs({ data, location }) {
  // console.log(data)
  return (
    <Layout>
      <Seo pathname={location.pathname} title="Blogs" />
      <HeadSection title="Blogs" subtitle="A selection of our finest blogs" />
      <Container>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16 py-12">
          {data.allSanityBlog.nodes.map((blog, key) => {
            return (
              <Link
                to={`/blog/${blog.slug.current}`}
                key={key}
                className="bg-gray-100 rounded overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <div className="bg-gray-300 h-56">
                  {blog.image ? (
                    <GatsbyImage
                      image={blog.image.asset.gatsbyImageData}
                      alt={blog.name}
                      className="h-56 w-full object-cover"
                    />
                  ) : (
                    <div className="h-full w-full" />
                  )}
                </div>
                <div className="p-4">
                  <div className="h-24">
                    <h3>{blog.name}</h3>
                  </div>
                  <div className="pt-6">
                    <a
                      href={`/blog/${blog.slug.current}`}
                      className="uppercase tracking-widest"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityBlog {
      nodes {
        name
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
